import React from 'react'
import Page from '../../components/Page'
import SEO from '../../components/seo'
import ModuleMenu from '../../components/Modules/ModuleMenu'
import {MAGNETIC_MODULE_DATA} from '../../components/Modules/module-data'
import ModuleContent from '../../components/Modules/ModuleContent'

const MagneticModulePage = () => (
  <Page>
    <SEO
      title="Magnetic Module GEN2 | Opentrons OT-2 Modular Hardware"
      description="The Opentrons Magnetic Module is a magnetic bead-based chemistry block for extraction & purification. It automatically engages and disengages high strength magnetic bars to seated well plates."
    />
    <ModuleMenu currentUrl={MAGNETIC_MODULE_DATA.basic.url} />
    <ModuleContent module={MAGNETIC_MODULE_DATA} />
  </Page>
)

export default MagneticModulePage
